import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { navigate } from 'gatsby'
import Breadcrumbs from '../breadcrumbs/breadcrumbs'
import { useQuery, useMutation } from '@apollo/client'
import {
  GET_SUSPENSION,
  LIST_SUSPENSIONS_FOR_PLAYER,
  UPDATE_SUSPENSION
} from '../players/players-queries'
import { meshGatewayClient } from 'src/apollo/client'
import Spinner from '../spinner/spinner'
import { NavigationState } from 'src/utils/typedefs/navigation'
import SuspensionForm from '../suspension-form/suspension-form'
import { H2 } from '../typography/typography'
import { usePlayerByExternalId } from 'src/hooks/data/suspensions'

interface Props {
  id: string
  suspensionId: string
}

const EditSuspension: React.FC<Props> = ({ id, suspensionId }) => {
  const { t } = useTranslation()
  const { fullName, loading: loadingPlayer } = usePlayerByExternalId(id)

  const { loading: loadingSuspension, data: suspensions } = useQuery(LIST_SUSPENSIONS_FOR_PLAYER, {
    client: meshGatewayClient,
    fetchPolicy: 'no-cache',
    variables: { uaid: id }
  })

  const { data: suspension } = useQuery(GET_SUSPENSION, {
    client: meshGatewayClient,
    variables: { id: suspensionId },
    fetchPolicy: 'no-cache',
    skip: !suspensionId
  })

  const [editSuspension, { loading }] = useMutation(UPDATE_SUSPENSION, {
    client: meshGatewayClient
  })

  const suspensionData = useMemo(() => {
    if (!suspension) {
      const suspensionList = suspensions?.listSuspensionsForPlayer
      return suspensionList?.[suspensionList?.length - 1]
    }

    return suspension.getSuspension
  }, [suspension, suspensions])

  const [selectedViolations, setSelectedViolations] = useState<any>([])

  const editSelectedPoints = (id, checked) => {
    let selected = selectedViolations
    if (checked === true) {
      selected.push(id)
    } else {
      selected = selected.filter(i => i !== id)
    }
    setSelectedViolations(selected)
  }

  const [formVals, setFormVals] = useState<any>(undefined)

  useEffect(() => {
    if (suspensionData) {
      const { startDate, endDate, comment } = suspensionData
      setFormVals({
        startDate: startDate,
        endDate: endDate,
        comment: comment
      })
    }
  }, [suspensionData])

  const handleSubmit = async values => {
    try {
      await editSuspension({
        variables: {
          id: suspensionId ?? suspensionData.id,
          input: values
        }
      })
      const state: NavigationState = {
        popup: {
          message: t('suspension updated')
        }
      }
      navigate(`/players/${id}?tab=suspensions`, { state })
    } catch (error) {}
  }

  return loadingPlayer ? (
    <Spinner />
  ) : (
    <>
      <Breadcrumbs
        paths={[
          { name: t('players'), to: '/players' },
          { name: fullName, to: `/players/${id}?tab=suspensions` },
          { name: t('edit suspension'), active: true }
        ]}
      />
      <H2 spacing={{ margins: { sm: 'top' } }}>{t('edit suspension')}</H2>
      {loadingSuspension && <Spinner />}
      {!loadingSuspension && formVals && (
        <SuspensionForm
          id={id}
          suspensionId={suspensionData?.id}
          handleSubmit={handleSubmit}
          loading={loading}
          formVals={formVals}
          editSelectedPoints={editSelectedPoints}
        />
      )}
    </>
  )
}

export default EditSuspension
