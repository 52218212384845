import React from 'react'
import { usePrivateRoute } from 'src/utils/auth'
import { Router, RouteComponentProps } from '@reach/router'
import Layout from 'src/components/layout/layout'
import SEO from 'src/components/seo'
import EditSuspension from 'src/components/edit-suspension/edit-suspension'

const EditSuspensionPage = () => {
  usePrivateRoute()
  return (
    <Router basepath="/players/:id/suspensions">
      <EditSuspensionRoute path="/:suspensionId" />
    </Router>
  )
}

interface EditSuspensionRouteProps extends RouteComponentProps {
  id: string
  suspensionId: string
}

const EditSuspensionRoute: React.FC<EditSuspensionRouteProps> = ({ id, suspensionId }) => {
  return (
    <Layout>
      <SEO title="Edit violation" />
      <EditSuspension id={id} suspensionId={suspensionId} />
    </Layout>
  )
}

export default EditSuspensionPage
